.activity-time {
  display: flex;
  justify-content: flex-end;
}

.activity-type {
  flex: 0 0 1.5rem;
  margin-right: 1.75rem;
  color: rgb(0, 95, 158);
}

.activity {
	flex: 1 1 auto;
	min-width: 0;
}

.activity-list.active {
	background-color: #e5f0f8;
}

.module.active {
	box-shadow: 0 0 .5rem #aeaeb6;
}
