.no-gap {
  margin-bottom: 0rem; /* This probably overrides a nonzero default */
}
.taskNamePaddingChop {
  padding-left: 0px;
}
.taskHeadingMarginChop {
  margin-left: 0px;
  padding-left: 0px;
}
.iconColWidth {
  margin-left: 96px;
  width: 0px;
}
.iconMarginWidth {
  margin-left: 36px;
  margin-right: 36px;
}
