.ReactVirtualized__Grid {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.column-select-menu.select-menu.name {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: flex-start !important;
}

.column-select-menu.select-menu.name > div {
  max-width: unset !important;
}

.column-select-menu.select-menu.dropdown-pane {
  min-width: auto !important;
}

.column-select-menu.select-menu.dropdown-pane.dropdown-list li {
  padding-left: 1.5rem !important;
}

.column-select-menu.select-menu.sort-icon {
  position: absolute;
  left: 0.3rem;
}

/* CommonTableRsCol row outline fix */
.ReactVirtualized__Table__row {
  outline-style: none;
}
