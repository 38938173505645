body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.works-os-icon_svg__prefix__root {
  height: 32px;
  width: 32px;
  fill: #6a6976 !important;
}

.display-linebreak {
  white-space: pre-line;
}

.inline-block {
  display: inline-block
}

.list .list-item.group .group-items {
  min-height: 3rem !important;
}

.connectContent {
  height: 100% !important;
  margin-top: 0 !important;
}

/* Show right panel class also less than 1200px */
@media (max-width: 1200px) {
  .show-right-panel-small-screen {
      display: block !important;
  }
}